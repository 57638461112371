export const Intro = (props) => {
  return (
    <div id="intro">
      <div className="container">
        <div className="row">

          <div className="col-xs-12 col-lg-7">
            <img src="img/aboutme.png" className="img-fluid rounded" alt="Aleksandra Kawa" />
          </div>

          <div className="col-xs-12 col-lg-5 ps-6">
            <div className='section-title'>
              <h1>Jesteśmy w tym razem</h1>
            </div>

            <p>
              Doświadczenie czegoś trudnego, z czym nie potrafimy sobie poradzić, może przydarzyć się każdemu. Nie mówi to w żadnym wypadku o tym, że jesteśmy słabi czy zepsuci, tylko o tym, że jesteśmy ludźmi.
              <hr className="w-100 mx-auto"></hr>
              Wierzę, że świat, w którym wsparcie psychologiczne nie jest niczym niezwykłym, tylko jednym z możliwych sposobów zatroszczenia się o siebie, to świat zdrowszy i spokojniejszy.
            </p>
          </div>
        </div>
      </div>
    </div>

  );
};
