export const Contact = (props) => {
  return (
    <div>
      <div id='contact'>
        <div className='container'>

          <div className='row'>

            <div className="col-xs-12 col-lg-6 p-2">
              <div className='section-title-secondary'>
                <h2>Kontakt</h2>
              </div>
              <div className='contact-item'>
                <p>
                  <i className='fa fa-envelope-o'></i> {props.data ? props.data.email : 'loading'}
                </p>
              </div>

              <p className="pe-lg-5 me-lg-5"> Informuję, że obecnie nie przyjmuję nowych osób na konsultacje psychologiczne. </p>
            </div>


            <div className="col-xs-12 col-lg-6 p-4 d-flex align-items-center">
              <img src="img/help.png" className="img-fluid rounded" alt="Rozmowy mogą leczyć" />
            </div>

          </div>

          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.insta : '/'}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.google : '/'}>
                      <i className='fa fa-google'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.znanylekarz : '/'}>
                      <i className='fa fa-user-md'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twojpsycholog : '/'}>
                      <i className='fa fa-heartbeat'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div id='footer' className='container'>
        <p>&copy; 2022 pblazyca</p>
      </div>

    </div>
  )
}
