export const SupportAreas = (props) => {
  return (
    <div id='support-areas'>
      <div className='container'>
        <div className="row">
          <div className="col">
            <div className='section-title-secondary'>
              <h2>Obszary wsparcia</h2>
            </div>

            <p>Pracuję z osobami dorosłymi w następujących obszarach:</p>

            <div className="row row-cols-lg-3">
              {props.data ? props.data.Areas.map((d, i) => (
                <div key={`${d.title}-${i}`} className='pe-3 pt-3 pb-3'>
                  <h4>{d.title}</h4>
                  <div dangerouslySetInnerHTML={{ __html: d.description }}></div>
                </div>)) : 'loading'}
            </div>
          </div>
        </div>

        <div className="row">
          <img src="img/health.png" className="img-fluid rounded mx-auto d-block w-50 p-4" alt="Możesz czuć dumę gdy szukasz pomocy" />
        </div>

        <div className="row">
          <div className='section-title-secondary'>
            <h3>Czy można się do mnie zapisać na psychoterapię?</h3>
            <p>
              Nie prowadzę psychoterapii; zajmuję się innymi formami pomocy psychologicznej.
              Wsparcie psychologa skierowane jest do osób doświadczających różnorodnych problemów w życiu codziennym.
              Poprzez różne oddziaływania (m.in. wywiad diagnostyczny, psychoedukację, trening umiejętności psychologicznych),
              można uzyskać pomoc w wypracowaniu sposobów poradzenia sobie z bieżącymi trudnościami i nabyć nowe zdolności wspierania siebie.
              Z kolei psychoterapia jest metodą leczenia wskazaną szczególnie dla osób, u których rozpoznano złożone trudności w funkcjonowaniu psychicznym. Jeżeli pojawi się taka potrzeba, zalecam i omawiam dalsze formy pomocy (np. psychoterapię, farmakoterapię) oraz kieruję do innych specjalistów.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
