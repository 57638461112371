import { Nav, Navbar, Container } from 'react-bootstrap';

export const Navigation = () => {
  return (
    <Navbar id='menu' className='navbar-default' collapseOnSelect expand="lg" bg="light" variant="light" >
      <Container >

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav className="md-auto">
            <Nav.Link href="#intro">O mnie</Nav.Link>
            <Nav.Link href="#support-areas">Obszary wsparcia</Nav.Link>
            <Nav.Link href="#price-list">Szcegóły i Cennik</Nav.Link>
            <Nav.Link href="#contact">Kontakt</Nav.Link>
          </Nav>

        </Navbar.Collapse>

      </Container>
    </Navbar>
  )
}
