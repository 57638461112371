export const Recommendation = (props) => {
  return (
    <div id='recommendation'>
      <div className='container'>
        <div className='row'>


          <div className="col-xs-12 col-lg-12">
            <div className="section-title">
              <h2>Moje artykuły</h2>
            </div>

            <p>Obok psychologii, moją wielką pasją jest literatura. Cieszy mnie też możliwość wykorzystywania znaczenia terapeutycznego książek, które czytamy. Dlatego z radością współtworzę portal:</p>

            <div className="row px-5">
              <a href="https://www.opsychologii.pl" className='btn highlight-button btn-lg'> opsychologii </a>
            </div>

            <br />
            <p>Artykuły mojego autorstwa można znaleźć tutaj: </p>

            <div class="row row-cols-1 row-cols-md-3 g-4">
              {props.data ? props.data.map((d, i) => (
                <div class="col">
                  <a href={d.link}>
                    <div class="card">
                      <img src={d.imgsource} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h4 class="card-title">{d.name}</h4>
                      </div>
                    </div>
                  </a>
                </div>
              )) : 'loading'}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
