export const About = (props) => {
  return (
    <div id="about">
      <div className="container">

        <div className="row">
          <div className="section-title">
            <h2>O mnie</h2>
            <p>Udzielam pomocy osobom dorosłym, a specjalizuję się w pracy z młodymi dorosłymi (20-30 lat). </p>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-lg-6 pe-4">
            <h4>Jak pracuję?</h4>
            {props.data ? props.data.Paragraphs.map((paragraph, index) => (
              <p key={`${paragraph}-${index}`}>{paragraph}</p>
            )) : "loading"}

            <h4>Co jest dla mnie ważne w pracy?</h4>
            {props.data ? props.data.Paragraph.map((paragraph, index) => (
              <p key={`${paragraph}-${index}`}>{paragraph}</p>
            )) : "loading"}
          </div>

          <div className="col-xs-12 col-lg-6 ps-4">
            <h4>Moje wykształcenie i doświadczenie</h4>
            {props.data ? props.data.ExperienceParagraphs.map((paragraph, index) => (
              <p key={`${paragraph}-${index}`}>{paragraph}</p>
            )) : "loading"}
          </div>
        </div>

      </div>
    </div>
  );
};
